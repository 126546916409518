p,h4{
    margin-bottom: 0;
}
.ownerInfo{
    margin: 10px 0px ;
    padding: 5px;
    display: flex;
    align-items: center;
}

.info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
}

.avatar{
    margin:0px 10px;
    margin-top: -22px;
}
.badge{
    margin-right: 10px;
}
.publicShareBtnsContainer{
    display: flex;
    gap: 16px;
    float: right;
    margin-top: 16px;
    margin-bottom: -16px;
    margin-right: -20px;
    width: fit-content;
}
.shareBtn{
    background-color: rgb(82, 107, 177);
    color: rgb(255, 255, 255);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.removeAccessBtn{
    background-color: rgb(219, 82, 82);
    color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.removeAccessBtn:hover{
    background-color: rgb(219, 82, 82);
    color: white;
}
.shareBtn:hover{
    background-color: rgb(82, 107, 177);
    color: white;
}
@media screen and (max-width: 480px) {
    p{
        font-size: 11px;
    }
}


